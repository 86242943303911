.header-overlay {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    background: #446E86;
    opacity: 0.6;
}

.header-overlay2 {
    height: 100vh;
    position: absolute;
    top: 50;
    left: 0;
    width: 100vw;
    z-index: 99;
}

.main-div {
    position: relative;
    top: 20vh;
  }
  
  .CentralDivContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .CentralDivContent {
    background-color: rgba(255, 255, 255, 0.9);
    padding-top: 10px;
    background-size: cover no-repeat center center fixed;
    min-height: 50vh;
    min-width: 60vh;
    font-size: calc(10px + 2vmin);
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }