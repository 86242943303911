.navigation {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0px;
}

.central-div {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
}

.menuText {
    font-size: 18px;
}
