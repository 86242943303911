.col-12,
.col-6,
.col-lg-8,
.col-sm-6,
.col-sm-4 {
padding: 1rem;
background-color: #fff;
border: 2px solid #fff;
color: #000;
text-align: center;
}