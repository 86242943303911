@media (max-width: 767px) {
    .column-1,
    .column-2 {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .column-1 {
        width: 50%;
    }
    .column-2 {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .column-2,
    .column-1 {
        width: 33%;
    }
}